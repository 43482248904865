import React, { Component } from "react";
import * as authActions from '../../redux/actions/authActions';
import * as appHeaderActions from "../../redux/actions/appHeaderActions";
import "../../css/style.css";
import history from "../../history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditProfile from "../../components/EditProfile";
import { getLocalStorageAsJSON } from "../../storage";
import { LS_USER_DETAILS_KEY } from "../../App.config";

class EditProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ein: "",
      employeeId: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      phoneNumber: "",
      profileImage: "",

      errors: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        phoneNumber: "",
      },
      isSubmitted: null,
      enableEdit: false
    };
  }

  componentDidMount() {
    this.props.authActions.getUserProfile((result) => {
      if (result?.profile) {
        const profileData = result?.profile;
        if (profileData.dob) {
          profileData.dob = profileData.dob.split('T')[0]
        }
        this.setState({ ...this.state, ...profileData });
      }
    })
    this.props.appHeaderActions.setData({ title: "View Profile", isBack: true });
  }

  setProfileImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ profileImage: reader.result.substr(reader.result.indexOf(',') + 1) });
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error);
    };
  }

  handleEdit = () => {
    this.setState({ enableEdit: true });
    this.props.appHeaderActions.setData({ title: "Edit Profile", isBack: true });
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value, files } = event.target;

    if (name === 'profileImage' && files && files[0]) {
      this.setProfileImage(files[0]);
    }
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { ein, employeeId, email, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber, profileImage } = this.state;
        const profile = { ein, employeeId, email, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber, profileImage };
        this.props.authActions.doUpdateProfile({
          profile
        });
      }
    });
  };

  isValid = () => {
    const { email, password, confirmPassword, firstName, lastName, dob, gender, phoneNumber, isSubmitted } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!email) {
      errors.email = "Require";
    }
    if (password != confirmPassword) {
      errors.confirmPassword = "パスワードと確認用パスワードは同じ値を設定してください。";
    }
    if (!firstName) {
      errors.firstName = "Require";
    }
    if (!lastName) {
      errors.lastName = "Require";
    }


    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };


  render() {
    const { handleChange, handleSubmit, handleEdit } = this;
    const props = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleEdit
    };
    return <EditProfile {...props} />;

  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    appHeaderActions: bindActionCreators(appHeaderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen);
